import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    API_RESOURCE_LOCATION,
    API_RESOURCE_PRODUCT,
    API_RESOURCE_OFFER,
} from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';
import { getActivePromoCodeInLocation } from 'Utils/promoCode';

import Spinner from 'Components/layout/Spinner';
import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import TrainingSelectForm from 'Components/public/reservations/SlotSelectForm';
import CheckoutForm from 'Components/public/reservations/CheckoutForm';

export default class PublicReservationsOnline extends Component {
    productId = process.env.APP_ENV == 'development' || process.env.APP_ENV == 'staging'
        ? '91538a88-78a0-45e0-ad38-113a9c0b8c4e'
        : '0d7c74e2-c9cd-4e57-bceb-800893b32e39';
    locationSlug = 'online';
    offerSlug = 'diagnostyczna-online-rezerwacje';

    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            getLocationBySlug: PropTypes.func.isRequired,
            singleOfferBySlug: PropTypes.func.isRequired,
            singleProduct: PropTypes.func.isRequired,
            singleShopBySlug: PropTypes.func.isRequired,
        }).isRequired,
        shopPage: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };

    static defaultProps = {};

    state = {
        selectedLocation: null,
        selectedOffer: null,
        selectedProduct: null,
        selectedSlot: null,
        formState: null,
        code: null,
    }

    componentDidMount = () => {
        const { location } = this.props;
        const { search } = location;
        
        this.loadLocation();
        this.loadProduct();
        this.loadOffer();
        this.loadShop();

        const queryObject = parseQueryToObject(search, true);
        const code = queryObject.code;
        this.setState(prevState => ({ 
            formState: { 
                ...prevState.formState, 
                code,
            },
        }));
    }

    loadProduct = () => {
        const { actions } = this.props;

        actions.singleProduct({ id: this.productId })
            .then(response => {
                this.setState({ selectedProduct: response.payload[API_RESOURCE_PRODUCT] });
            });
    }

    loadOffer = () => {
        const { actions } = this.props;

        actions.singleOfferBySlug({ slug: this.offerSlug })
            .then(response => {
                this.setState({ selectedOffer: response.payload[API_RESOURCE_OFFER] });
            });
    }

    loadLocation = () => {
        const { actions } = this.props;

        actions.getLocationBySlug({ slug: this.locationSlug })
            .then(response => {
                const location = response.payload[API_RESOURCE_LOCATION];
                const discountCode = getActivePromoCodeInLocation(location);

                this.setState(prevState => ({
                    ...prevState,
                    selectedLocation: location,
                    formState: {
                        ...prevState.formState,
                        comment: discountCode,
                    },
                }));
            });
    }

    loadShop = () => {
        const { actions } = this.props;

        actions.singleShopBySlug({
            slug: this.locationSlug
        });
    }

    onTrainingSelectFormSubmit = data => {
        this.setState({ selectedSlot: data });
    }

    onResetTraining = () => {
        this.setState({
            selectedSlot: null,
        });
    }

    onFormStateChange = (formState) => {
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...formState,
            },
        }));
    }

    render() {
        const { location, history, match, shopPage } = this.props;
        const { selectedOffer, selectedProduct, selectedLocation, selectedSlot, formState } = this.state;
        const queryObject = parseQueryToObject(location?.search || '', true);

        if (!selectedOffer || !selectedLocation || !selectedProduct || !shopPage.isLoaded) {
            return (
                <Spinner />
            );
        }

        return (
            <StyledComponent
                className={classnames({
                    'component-reservations': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />

                <LayoutContainer>
                    {!selectedSlot && (
                        <div className="content content-training-select">
                            <div className="body">
                                <div className="training-select-container">
                                    <TrainingSelectForm
                                        location={location}
                                        history={history}
                                        selectedLocation={selectedLocation}
                                        selectedProduct={selectedProduct}
                                        leadId={queryObject?.leadId}
                                        onSubmit={this.onTrainingSelectFormSubmit}
                                        lagValue={shopPage?.data?.lagValue}
                                        lagType={shopPage?.data?.lagType}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {selectedSlot && (
                        <div className="content content-checkout">
                            <div className="body">
                                <div className="checkout-container">
                                    <CheckoutForm
                                        location={location}
                                        history={history}
                                        match={match}
                                        onResetTraining={this.onResetTraining}
                                        onFormStateChange={this.onFormStateChange}
                                        data={formState}
                                        showComment={true}
                                        selectedOffer={selectedOffer}
                                        selectedLocation={selectedLocation}
                                        selectedProduct={selectedProduct}
                                        selectedSlot={selectedSlot.slot}
                                        canUserSendNote={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
